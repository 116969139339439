<template>
  <el-card>
    <div slot="header">
      <h3>我的咨询</h3>
    </div>

    <div class="body">
      <el-form :inline="true" :model="query" class="text-center">
        <el-form-item>
          <el-date-picker
              v-model="query.dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="query.id" placeholder="请输入ID"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>

      <el-row class="content">
        <el-col class="item" :span="12" v-for="i in list" :key="i">
          <el-row>
            <el-col :span="8">
              <el-image :src="i.pic" fit="fill"></el-image>
            </el-col>
            <el-col :span="16">
              <h3>{{i.name}}</h3>
              <p>咨询时间：{{i.dotime}}</p>
              <!-- <p>带人咨询</p> -->

              <el-button type="primary">开始咨询</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <el-pagination
          background
          layout="prev, pager, next"
          :total="1000">
      </el-pagination>
	  
    </div>


  </el-card>
</template>

<script>
  import axios from "axios"; 
  export default {
    name: "ConsultIndex",
    data() {
      return {
        tabActive: 'first',
        query: {},
        url: require("../../../assets/avatar/上官芝.jpg"),
		list:[],
		urls:''
      }
    },
		created(){
			
			 //1患者 2医生 3企业			const type = sessionStorage.getItem('type');		 			if(type==1){				this.urls = this.apiUrl+'/api/user/getVisitDatePatientValue';			}						if(type==2){				this.urls = this.apiUrl+'/api/user/getVisitDateDoctorValue';			}		
			const params = new URLSearchParams();
			params.append('uid', sessionStorage.getItem('uid')); 
		 
			axios.post(this.urls,params)
			.then((response) => {   
				this.list = response.data.data; 
				 console.log('aaaaaaaaaaaaaaaaaaa');
				 console.log(this.list);
				 console.log('aaaaaaaaaaaaaaaaaaa');
			}) 
			.catch(function(error) {
			  console.log(error);
			});
		},
  }
</script>

<style scoped lang="scss">

  .el-card {
    min-height: 1050px;

    /deep/ .el-card__header {
      padding: 18px 20px 0px 20px;

      h3 {
        padding: 0px;
        margin: 0px;
      }

      .el-alert {
        margin-top: 10px;
      }
    }

    .body {
      .content {
        padding: 20px;
        .item {
          margin-bottom: 40px;

          h3 {
            margin-top: 0px;
          }

          .el-image {
            width: 125px;
            height: 130px;
          }

          p {
            margin: 5px 0px;
            color: #666666;
          }

          .el-button {
            margin-top: 12px;
          }
        }
      }
      /deep/ .el-pagination{
        text-align: center;
      }

    }

  }


</style>
